import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import StoryPreview from "../components/story-preview";

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const stories = get(this, "props.data.allContentfulStory.edges");
    const [profile] = get(this, "props.data.allContentfulProfile.edges");

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={profile.name} />
          <Hero data={profile.node} />
          <div className="wrapper">
            {/* <h2 className="section-headline">BlackinTech Berlin Job Board</h2> */}
            <ul className="article-list">
              {stories.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <StoryPreview story={node} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulStory(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          countryOfOrigin {
            name
            flag {
              fluid(maxWidth: 100) {
                ...GatsbyContentfulFluid
              }
              fixed(width: 20) {
                ...GatsbyContentfulFixed
              }
            }
          }
          credit {
            name
            url
          }
        }
      }
    }
    allContentfulProfile(
      filter: { contentful_id: { eq: "5EVtj1w91Qc6QjCyHgfhp9" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          email
          heroImage {
            fixed(width: 100, height: 150) {
              ...GatsbyContentfulFixed
            }
          }
          logo {
            fixed(width: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
